<template>
  <div
    id="id_commitments"
    class="my-5"
  >
    <!-- Title -->
    <div class="mb-3">
      <div class="text-center">
        <h1>{{ $t("commitments.commitmentsTitle") }}</h1>
      </div>
    </div>

    <div class="commits text-center">
      <b-row
        class="text-center"
        align-h="center"
      >
        <!-- Quality -->
        <b-col
          class="mb-3 text-center d-flex justify-content-center align-items-center"
          xl="2"
          lg="4"
          md="6"
        >
          <a
            href="/quality-environment"
            class="commitments"
          >
            <a
              v-if="showCalidad"
              @mouseover="showCalidad = !showCalidad"
            >
              <commit
                :title="data[0].title"
                :image="data[0].image"
              />
            </a>

            <a
              v-else
              @mouseleave="showCalidad = !showCalidad"
            >
              <commit
                :subtitle="data[0].title"
                :desc="data[0].desc"
                :image="data[0].image"
              />
            </a>
          </a>
        </b-col>

        <!-- Environment -->
        <b-col
          class="mb-3 text-center d-flex justify-content-center align-items-center"
          xl="2"
          lg="4"
          md="6"
        >
          <a
            href="/quality-environment"
            class="commitments"
          >
            <a
              v-if="showAmbiente"
              @mouseover="showAmbiente = !showAmbiente"
            >
              <commit
                :title="data[1].title"
                :image="data[1].image"
              />
            </a>

            <a
              v-else
              @mouseleave="showAmbiente = !showAmbiente"
            >
              <commit
                :subtitle="data[1].title"
                :desc="data[1].desc"
                :image="data[1].image"
              />
            </a>
          </a>
        </b-col>

        <!-- Security -->
        <b-col
          class="mb-3 text-center d-flex justify-content-center align-items-center"
          xl="2"
          lg="4"
          md="6"
        >
          <a
            href="/security"
            class="commitments"
          >
            <a
              v-if="showSeguridad"
              @mouseover="showSeguridad = !showSeguridad"
            >
              <commit
                :title="data[2].title"
                :image="data[2].image"
              />
            </a>

            <a
              v-else
              @mouseleave="showSeguridad = !showSeguridad"
            >
              <commit
                :subtitle="data[2].title"
                :desc="data[2].desc"
                :image="data[2].image"
              />
            </a>
          </a>
        </b-col>

        <!-- Innovation -->
        <b-col
          class="mb-3 text-center d-flex justify-content-center align-items-center"
          xl="2"
          lg="4"
          md="6"
        >
          <a
            href="/innovation"
            class="commitments"
          >
            <a
              v-if="showInnovacion"
              @mouseover="showInnovacion = !showInnovacion"
            >
              <commit
                :title="data[3].title"
                :image="data[3].image"
              />
            </a>

            <a
              v-else
              @mouseleave="showInnovacion = !showInnovacion"
            >
              <commit
                :subtitle="data[3].title"
                :desc="data[3].desc"
                :image="data[3].image"
              />
            </a>
          </a>
        </b-col>

        <!-- Equality -->
        <b-col
          class="mb-3 text-center d-flex justify-content-center align-items-center"
          xl="2"
          lg="4"
          md="6"
        >
          <a
            href="/equality"
            class="commitments"
          >
            <a
              v-if="showEquality"
              @mouseover="showEquality = !showEquality"
            >
              <commit
                :title="data[4].title"
                :image="data[4].image"
              />
            </a>

            <a
              v-else
              @mouseleave="showEquality = !showEquality"
            >
              <commit
                :subtitle="data[4].title"
                :desc="data[4].desc"
                :image="data[4].image"
              />
            </a>
          </a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Commit from 'Components/AboutUs/Commit.vue'

import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'Commitments',
  components: {
    Commit,
    BRow,
    BCol,
  },
  props: {
    data: {
      type: Array,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      showCalidad: true,
      showAmbiente: true,
      showSeguridad: true,
      showInnovacion: true,
      showEquality: true,
    }
  },
}
</script>

<style scoped>
.commitments {
  background-size: cover;
  position: relative;
}

.text {
  position: absolute;
}

a {
  font-size: 22px;
  font-weight: bold;
  display: block;
  cursor: pointer;
  background-size: cover;
  height: 100%;
}
</style>
