<template>
  <div
    id="id_values"
    class="my-5"
  >
    <div class="container block-head">

      <!-- Title -->
      <h1 class="head-line text-center mb-4">
        {{ $t('team.valuesTitle') }}
      </h1>

      <!-- Desc -->
      <template v-for="item in $t('team.valuesList')">
        <div
          :key="item"
          class="container"
        >
          <ul class="mx-0 p-0">
            <li>
              {{ item }}
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OurValues',
}
</script>

<style scoped>

  ul {
    list-style: none;
  }

  li::before {
    content: "•";
    color: #ff8f00
  }

  li {
    color: black !important;
    font-size: 20px;
  }

  @media (max-width: 700px){
    li {
      font-size: 14px;
    }
  }

</style>
