<template>
  <div
    id="id_team"
    class="mb-3"
  >
    <div class="container">
      <div class="block-head text-center">

        <!-- Title -->
        <h1> {{ $t('team.teamTitle') }} </h1>

        <!-- Subtitle -->
        <p class="text-justify">
          {{ $t('team.teamDesc') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TeamLimmat',
}
</script>
