<template>
  <div
    id="id_offers"
    class="back"
  >

    <!-- Title 1 -->
    <h1
      v-if="data"
      class="head-line text-center"
    >
      {{ $t('commitments.jobTitle') }}
    </h1>

    <!-- Job Offers -->
    <div
      v-for="list of data"
      :key="list.title"
      class="d-flex align-items-center justify-content-center"
    >
      <b-card class="mt-3 mb-3 jobOfferCard">
        <b-row>

          <!-- Image -->
          <b-col
            md="12"
            lg="3"
          >
            <b-img-lazy
              :src="list.image"
              alt="jobOffer-Image"
              style="height: 200px; border-radius: 20px"
            />
          </b-col>

          <!-- Title, subtitle -->
          <b-col
            md="12"
            lg="7"
          >
            <h1 style="color: black; font-size: 22px;">
              {{ list.title }}
            </h1>

            <h1 style="color: grey; font-size: 14px;">
              <i class="fa fa-calendar text-black" />
              Posted on: {{ list.date }}
            </h1>

            <h3 style="font-size: 17px;">
              {{ list.desc }}
            </h3>
          </b-col>

          <!-- Button, address -->
          <b-col
            md="12"
            lg="2"
          >
            <b-button
              class="mb-5 bbutton"
              style="text-transform: uppercase;"
              href="https://www.linkedin.com/company/limmat-group"
              variant="primary"
              pill
              target="_blank"
              rel="noopener"
            >
              {{ $t('commitments.viewJob') }}
            </b-button>

            <h1 style="color: grey; font-size: 14px;">
              <i class="pe-7s-map-marker pe-2x pe-va" />
              Segovia, Spain
            </h1>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <div class="container">

      <!-- Title 2 -->
      <h1 class="text-center mt-5">
        {{ $t('commitments.askTitle') }}
      </h1>

      <p class="text-center">
        {{ $t('commitments.askDesc') }}
      </p>

      <b-button
        class="mt-3 mb-5 center bbutton"
        style="text-transform: uppercase;"
        href="/contact"
        variant="primary"
        pill
        target="_blank"
        rel="noopener"
      >
        {{ $t('commitments.generalApplication') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import 'pixeden-stroke-7-icon'

import {
  BRow,
  BCol,
  BCard,
  BButton,
  BImgLazy,
} from 'bootstrap-vue'

import MyData from 'Data/aboutUs.json'

export default {
  name: 'JobOffers',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BImgLazy,
  },
  data() {
    return {
      data: MyData.data.jobOffers,
    }
  },
}
</script>

<style scoped>
  .jobOfferCard {
    width: 1000px;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    margin: 20px;
    align-items: center;
  }

  .bbutton:focus{
    background-color: #ff8f00;
    border-color: #ff8f00;
    box-shadow: none;
  }

  p {
    margin-right: 20px;
    margin-left: 20px;
  }

</style>
