<template>
  <div class="category-box">

    <!-- Background Image -->
    <b-img-lazy
      :src="image"
      alt="Background Image"
      style="width: 100%; height:100%;"
    />

    <!-- Over Text -->
    <div class="category-box-content text-center">

      <b-img-lazy
        v-if="icon"
        :src="icon"
        alt="Icon Image"
        class="mb-4"
      />

      <h4 v-if="title">
        {{ title }}
      </h4>

      <h2
        v-if="imasTitle"
        style="font-size: 25px"
      >
        {{ imasTitle }}
      </h2>

      <div v-else-if="desc">

        <h4>
          {{ subtitle }}
        </h4>

        <hr class="solid">

        <h6>
          {{ desc }}
        </h6>
      </div>
    </div>
  </div>
</template>

<script>

import { BImgLazy } from 'bootstrap-vue'

export default {
  name: 'Commit',
  components: {
    BImgLazy,
  },
  props: {
    title: {
      type: String,
      default: null,
      required: false,
    },
    imasTitle: {
      type: String,
      default: null,
      required: false,
    },
    subtitle: {
      type: String,
      default: null,
      required: false,
    },
    desc: {
      type: String,
      default: null,
      required: false,
    },
    image: {
      type: String,
      default: null,
      required: true,
    },
    icon: {
      type: String,
      default: null,
      required: false,
    },
  },
}
</script>

<style scoped>
  h4 {
    font-size: 25px;
    color: #ff8f00 !important;
  }

  h6 {
    color: white !important;
    font-size: 15px;
  }

  @media (max-width: 700px){
    h4 {
      font-size: 17px !important;
    }

    h6 {
      color: white !important;
      font-size: 14px;
    }
  }

  @media (min-width: 992px) and (max-width: 1200px){
    h6 {
      color: white !important;
      font-size: 15px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1400px){
    h4 {
      font-size: 14px !important;
    }

    h6 {
      color: white !important;
      font-size: 9px;
    }
  }

  @media (min-width: 1400px) and (max-width: 1600px){
    h4 {
      font-size: 17px !important;
    }

    h6 {
      color: white !important;
      font-size: 12px;
    }
  }

  @media (min-width: 1600px) and (max-width: 2000px){
    h4 {
      font-size: 20px !important;
    }

    h6 {
      color: white !important;
      font-size: 12px;
    }
  }

  hr.solid {
    border-top: 2px solid #999;
    margin-top: 1px;
    margin-bottom: 10px
  }

</style>
