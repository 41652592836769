<template>
  <div>
    <!-- Banner -->
    <banner
      :video="data.bannerVideo"
      height="600"
    />

    <!-- Commitments -->
    <commitments
      v-if="language === 'en'"
      :data="dataEn"
    />
    <commitments
      v-else
      :data="dataEs"
    />

    <!-- Team Limmat -->
    <team-limmat />

    <!-- Image Separator -->
    <image-separator
      :image="data.imageSeparator"
      :title="data.imageTitle"
    />

    <!-- Our Values -->
    <our-values />

    <!-- Image Separator -->
    <image-separator
      :image="data.imageSeparator2"
      :title="$t('team.imageTitle2')"
    />

    <!-- Job Offers -->
    <job-offers />
  </div>
</template>

<script>
import Banner from 'Components/Common/Banner.vue'
import TeamLimmat from 'Components/AboutUs/TeamLimmat.vue'
import ImageSeparator from 'Components/Common/ImageSeparator.vue'
import OurValues from 'Components/AboutUs/OurValues.vue'
import Commitments from 'Components/AboutUs/Commitments.vue'
import JobOffers from 'Components/AboutUs/JobOffers.vue'

import MyData from 'Data/aboutUs.json'

export default {
  name: 'AboutUs',
  components: {
    Banner,
    TeamLimmat,
    ImageSeparator,
    OurValues,
    Commitments,
    JobOffers,
  },
  data() {
    return {
      data: MyData.data,
      language: null,
      dataEs: MyData.data.es,
      dataEn: MyData.data.en,
    }
  },
  mounted() {
    this.language = this.getLanguage()
  },
  methods: {
    getLanguage() {
      return localStorage.getItem('lang')
    },
  },
}
</script>
